import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject, of, takeUntil, interval } from 'rxjs';
import { map, catchError, switchMap, finalize, startWith } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { Router } from '@angular/router';
import { TokenStorageService } from '@/_core/services';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  destroy$: Subject<boolean>;
  timeout: number;
  refreshToken$: Subject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private tokenService: TokenStorageService,
    private jwtService: JwtHelperService
  ) {
    this.timeout = environment.settings.timeout;
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.destroy$ = new Subject<boolean>();
    this.refreshToken$ = new Subject<boolean>();
    this.getUserCache().pipe(takeUntil(this.destroy$)).subscribe();
  }

  // public methods
  login(username: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(username, password).pipe(
      map((auth: AuthModel) => {
        this.tokenService.saveTokens(auth.authToken);
        const result = this.tokenService.setAuth(auth);
        this.refreshToken().subscribe();
        return result;
      }),
      switchMap(() => this.getUser()),
      catchError((err) => {
        console.error('Login Error', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  refreshToken() {
    this.refreshToken$.next(false);
    return interval(this.timeout)
      .pipe(takeUntil(this.refreshToken$))
      .pipe(
        switchMap(() => this.authHttpService.refreshToken()),
        catchError((err) => {
          console.error('Refresh token Error', err);
          this.refreshToken$.next(true);
          return of(undefined);
        })
      )
      .pipe(
        map((auth: AuthModel) => {
          if (auth) {
            this.tokenService.saveTokens(auth.authToken);
            const result = this.tokenService.setAuth(auth);
            return result;
          }
          return false;
        })
      );
  }

  logout(url: string = null) {
    // const token = this.tokenService.getAccessToken();
    // this.authHttpService.logout(token).pipe(takeUntil(this.destroy$)).subscribe();
    this.refreshToken$.next(true);
    this.tokenService.removeTokens();
    const queryParams = url ? { queryParams: { returnUrl: url } } : { queryParams: {} };
    this.router.navigate(['/auth/login'], queryParams);
  }

  getUser(): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUser().pipe(
      map((user: UserType) => {
        if (user) {
          user.pic = user.pic || './assets/media/avatars/blank.png';
          this.tokenService.setUser(user);
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUserCache(): Observable<UserType> {
    const auth = this.tokenService.getAuth();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const isTokenExpired = this.jwtService.isTokenExpired(auth.authToken);
    if (isTokenExpired) {
      this.tokenService.removeTokens();
      return of(undefined);
    }

    const user = this.tokenService.getUser();
    if (user) {
      this.currentUserSubject.next(user);
      this.refreshToken().subscribe();
    }
    return of(user);
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('Registration Error', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.forgotPassword(email).pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.refreshToken$.next(true);
    this.refreshToken$.unsubscribe();
  }
}
