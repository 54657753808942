import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';
import { AuthModel } from '@/modules/auth/models/auth.model';
import { UserModel } from '@/modules/auth';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {
  private accessTokenKey: string;
  private refreshTokenKey: string;
  private authLocalStorageToken = `${environment.settings.authKey}`;
  private userKey: string = `${environment.settings.userKey}`;

  constructor(
    private localStorageService: LocalStorageService,
    private configService: ConfigService,
    private jwtService: JwtHelperService
  ) {
    const authSettings = this.configService.getAuthSettings();
    this.accessTokenKey = authSettings.accessTokenKey || 'accessToken';
    this.refreshTokenKey = authSettings.refreshTokenKey || 'refreshToken';
  }

  getAccessToken(): string {
    const token = this.localStorageService.getItem(this.accessTokenKey) as string;
    if (token) {
      const isTokenExpired = this.jwtService.isTokenExpired(token);
      if (isTokenExpired) {
        return '';
      }
    }
    return token;
  }

  saveAccessToken(token: string) {
    this.localStorageService.setItem(this.accessTokenKey, token);
  }

  getRefreshToken(): string {
    return this.localStorageService.getItem(this.refreshTokenKey) as string;
  }

  saveRefreshToken(token: string) {
    this.localStorageService.setItem(this.refreshTokenKey, token);
  }

  saveTokens(accessToken: string) {
    this.saveAccessToken(accessToken);
  }

  removeTokens() {
    this.localStorageService.removeItem(this.accessTokenKey);
    this.localStorageService.removeItem(this.userKey);
    localStorage.removeItem(this.authLocalStorageToken);
  }

  setUser(user: UserModel) {
    this.localStorageService.setItem(this.userKey, user);
  }

  getUser(): UserModel {
    return this.localStorageService.getItem(this.userKey) as UserModel;
  }

  setAuth(auth: AuthModel): boolean {
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuth(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}
