import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Blog, BlogsPagination } from '../model/blog/blog.models';
import { DataResponse } from '../model/common/common.models';

@Injectable({
  providedIn: 'root'
})
export class GuestHTTPService {
  API_URL: string = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getTopNews(lang: string = 'en',): Observable<any> {
    return this.http.get<any>(this.API_URL + `/top-news?language=${lang}`).pipe(catchError(()=>of(undefined)));
  }

  getCommonConfigHttp(): Observable<any> {
    return this.http.get<any>(this.API_URL + `/common-config`).pipe(catchError(()=>of(undefined)));
  }

  postTracking(data: any): Observable<any> {
    return this.http.post<any>(this.API_URL + `/tracking`, data).pipe(catchError(()=>of(undefined)));
  }

  postContact(data: any): Observable<any> {
    return this.http.post<any>(this.API_URL + `/contact`, data).pipe(catchError(()=>of(undefined)));
  }

  getListNews(category: string, lang: string = 'en', page: number = 1, pageSize: number = 10): Observable<BlogsPagination> {
    return this.http.get<BlogsPagination>(this.API_URL + `/news/list?category=${category}&page=${page}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }
  getListRecentNews(category: string, lang: string = 'en'): Observable<BlogsPagination> {
    return this.http.get<BlogsPagination>(this.API_URL + `/news/recent-news?category=${category}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }

  getListCustomerNews(menu: string, category: string, lang: string = 'en', page: number = 1): Observable<BlogsPagination> {
    return this.http.get<BlogsPagination>(this.API_URL + `/customer-news/list?menu=${menu}&category=${category}&page=${page}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }

  getListRecentCustomerNews(menu: string, category: string, lang: string = 'en'): Observable<BlogsPagination> {
    return this.http.get<BlogsPagination>(this.API_URL + `/customer-news/recent-news?menu=${menu}&category=${category}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }

  getDetailNew(slug: string, lang: string = 'en'): Observable<DataResponse<Blog>> {
    return this.http.get<DataResponse<Blog>>(this.API_URL + `/news/detail?slug=${slug}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }

  getDetaiCustomerlNew(menu: string, slug: string, lang: string = 'en'): Observable<DataResponse<Blog>> {
    return this.http.get<DataResponse<Blog>>(this.API_URL + `/customer-news/detail?menu=${menu}&slug=${slug}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }

  getCustomerCategories(menu: string): Observable<DataResponse<Blog>> {
    return this.http.get<DataResponse<Blog>>(this.API_URL + `/customer-news/categories?menu=${menu}`).pipe(catchError(()=>of(undefined)));
  }

  getListRecruit(category: string, lang: string = 'en', page: number = 1, pageSize: number = 10): Observable<BlogsPagination> {
    return this.http.get<BlogsPagination>(this.API_URL + `/recruit/list?category=${category}&page=${page}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }
  getDetailRecruit(slug: string, lang: string = 'en'): Observable<BlogsPagination> {
    return this.http.get<BlogsPagination>(this.API_URL + `/recruit/detail?slug=${slug}&language=${lang}`).pipe(catchError(()=>of(undefined)));
  }
  getCategoryRecruit(category: string): Observable<BlogsPagination> {
    return this.http.get<BlogsPagination>(this.API_URL + `/recruit/categories?category=${category}`).pipe(catchError(()=>of(undefined)));
  }
  postCandidate(data: any): Observable<any> {
    return this.http.post<any>(this.API_URL + `/recruit/candidate`, data).pipe(catchError(()=>of(undefined)));
  }
}
