import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, switchMap } from 'rxjs';

import { ConfigService } from './config.service';
import { LocalStorageService } from './local-storage.service';
import { Receiver } from '../model/shipment/shipment.models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  API_URL: string;
  shipmentNo: BehaviorSubject<string>;
  receivers$: BehaviorSubject<Receiver[]>;

  constructor(private http: HttpClient, private configService: ConfigService, private localStorage: LocalStorageService) {
    this.API_URL = this.configService.getAPIUrl();
    this.shipmentNo = new BehaviorSubject<string>(undefined);
    this.receivers$ = new BehaviorSubject<Receiver[]>(undefined);
  }

  getReceiversValue(){
    return this.receivers$.value;
  }

  setShipmentNo(code: string) {
    this.shipmentNo.next(code);
  }

  getShipmentNo() {
    return this.shipmentNo.asObservable();
  }

  getShipment(code: string) {
    return this.http.get<any>(this.API_URL + `/user/shipment/detail?code=${code}`);
  }

  getDashboard() {
    return this.http.get<any>(this.API_URL + `/user/dashboard`);
  }

  getLatestShipper() {
    return this.http.get<any>(this.API_URL + `/user/shipment/latest-shipper`);
  }

  getListShipper() {
    return this.http.get<any>(this.API_URL + `/user/shipment/shippers`);
  }

  booking(data: any) {
    return this.http.post<any>(this.API_URL + `/user/shipment/booking`, data);
  }

  printBill(data: any) {
    const options = { responseType: 'blob' as 'json' };
    return this.http
      .post<Blob>(this.API_URL + `/user/shipment/print-bill`, data, options)
      .pipe(map((res) => new Blob([res], { type: 'application/pdf' })));
  }

  printInvoice(data: any) {
    const options = { responseType: 'blob' as 'json' };
    return this.http
      .post<Blob>(this.API_URL + `/user/shipment/print-invoice`, data, options)
      .pipe(map((res) => new Blob([res], { type: 'application/pdf' })));
  }

  printCommitmentLetter(data: any) {
    const options = { responseType: 'blob' as 'json' };
    return this.http
      .post<Blob>(this.API_URL + `/user/shipment/print-commitment-letter`, data, options)
      .pipe(map((res) => new Blob([res], { type: 'application/pdf' })));
  }

  getListShipments(category: string, startDate: string = '', endDate: string = '') {
    return this.http.get<any>(this.API_URL + `/user/shipment/list?category=${category}&startDate=${startDate}&endDate=${endDate}`);
  }
  getListCategory() {
    return this.http.get<any>(this.API_URL + `/user/shipment/categories`);
  }
  getListCategories() {
    return this.http.get<any>(this.API_URL + `/user/shipment/categories`);
  }
  getProjectInfo() {
    return this.http.get<any>(this.API_URL + `/user/project-information`);
  }

  changePassword(data: any) {
    return this.http.post<any>(this.API_URL + `/user/change-password`, data);
  }

  updateInformation(data: any) {
    return this.http.post<any>(this.API_URL + `/user/update-information`, data);
  }

  getListCountries() {
    return this.http.get<any>(this.API_URL + `/location/country`);
  }
  getListDistricts(id: number = 1) {
    return this.http.get<any>(this.API_URL + `/location/district?provinceId=${id}`);
  }
  getListProvinces(id: number = 1) {
    return this.http.get<any>(this.API_URL + `/location/province?countryId=${id}`);
  }
  getListWards(id: number = 1) {
    return this.http.get<any>(this.API_URL + `/location/ward?districtId=${id}`);
  }

  getListReceivers() {
    return this.http.get<any>(this.API_URL + `/user/shipment/receivers`).pipe(
      map((res) => {
        this.receivers$.next(res.data);
        return res.data;
      })
    );
  }
}
