import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { AuthModel } from '../../models/auth.model';
import { environment } from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(username: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/auth/login`, {
      username,
      password
    });
  }

  refreshToken(): Observable<AuthModel> {
    return this.http.get<AuthModel>(`${API_USERS_URL}/auth/refresh`);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/auth/forgot-password`, {
      email
    });
  }

  logout(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    console.log(token);
    return this.http.get<any>(`${API_USERS_URL}/auth/logout`, {
      headers: httpHeaders
    });
  }

  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/user/me`, {
      headers: httpHeaders
    });
  }

  getUser(): Observable<UserModel> {
    return this.http.get<UserModel>(`${API_USERS_URL}/user/me`);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_USERS_URL}/user/create`, user);
  }
}
