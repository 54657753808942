import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject, of, takeUntil, interval } from 'rxjs';
import { map, catchError, switchMap, finalize, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ConfigService } from './config.service';
import * as newsData from 'src/fake-data/rie-news.json';
import * as recruitsData from 'src/fake-data/recruits.json';
import { GuestHTTPService } from './guest-http.service';
import { Blog, BlogsPagination } from '../model/blog/blog.models';
import { Menu } from '../model/common/menu.models';
import { CommonConfig, DataResponse } from '../model/common/common.models';
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GuestService implements OnDestroy {
  commonConfig$: Observable<CommonConfig>;
  isLoading$: Observable<boolean>;
  commonConfigSubject: BehaviorSubject<CommonConfig>;
  menu$: Observable<Menu[]>;
  menuSubject: BehaviorSubject<Menu[]>;
  isLoadingSubject: BehaviorSubject<boolean>;
  destroy$: Subject<boolean>;

  API_URL: string = environment.apiUrl;

  data: any = newsData;
  dataRecruit: any = recruitsData;
  commonConfigKey: string;
  menuKey: string;
  lang: string;

  constructor(private config: ConfigService, private localStorageService: LocalStorageService, private guestHTTPService: GuestHTTPService, private translate: TranslateService) {
    this.lang = this.translate.currentLang;
    this.commonConfigKey = this.config.getCommonConfigKey();
    this.menuKey = this.config.getMenuKey();
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.commonConfigSubject = new BehaviorSubject<CommonConfig>(this.config.getCommonConfig(this.lang));
    this.commonConfig$ = this.commonConfigSubject.asObservable();
    this.menuSubject = new BehaviorSubject<Menu[]>([]);
    this.menu$ = this.menuSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.destroy$ = new Subject<boolean>();
    this.getCommonConfig(this.lang).subscribe();
  }

  getCommonConfig(language: string = 'en'): Observable<CommonConfig> {
    return this.guestHTTPService
      .getCommonConfigHttp()
      .pipe(takeUntil(this.destroy$))
      .pipe(
        map((res) => {
          this.commonConfigSubject.next(environment.commonConfig[language]);
          if (res.menus) {
            this.menuSubject.next(res.menus);
          }
          return environment.commonConfig[language];
        }),
        catchError((err) => {
          return of(undefined);
        })
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
